.leaflet-container {
  width: 100%;
  height: 100%;
}

.leaflet-container.crosshair-cursor-enabled {
  cursor: crosshair;
}

.ArrowHeadTooltip {
  padding: 3px;
}

.KPTooltip {
  color: red;
  -webkit-text-stroke: 0.3px black;
  font-size: 22px;
  font-weight: bold;
  border: 0ch;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
}