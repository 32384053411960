  .rtable {
  width: 100%;
  border-spacing: 0px;
}

.rtable thead {
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  font-weight: bold;
  background-color: #094d00;
  color: white;
  text-align: center;
  border: 2px solid #ffffff;
  border-bottom: 1px solid #d3d9df;
}
.rtable td {
  padding: 8px;
}
.rtable thead tr td {
  border: 1px solid #d3d9df;
}

.rtable tbody tr td {
  border: 0px;
  border-bottom: 1px solid #d3d9df;
  text-align: center;
}

.rtable tbody tr:nth-child(2n) {
  background-color: #f3f9ff;
}
.rtable tbody tr:nth-child(2n-1) {
  background-color: white;
}

.rtable tbody tr:hover {
  background-color: lightgreen;
  cursor: pointer;
}

@keyframes kfhighlightchangeodd {
  0% {
    background-color: white;
  }
  10% {
    background-color: red;
  }
  100% {
    background-color: white;
  }
}

@keyframes kfhighlightchangeodd1 {
  0% {
    background-color: white;
  }
  10% {
    background-color: red;
  }
  100% {
    background-color: white;
  }
}

@keyframes kfhighlightchangeeven {
  0% {
    background-color: #f3f9ff;
  }
  10% {
    background-color: red;
  }
  100% {
    background-color: #f3f9ff;
  }
}

@keyframes kfhighlightchangeeven1 {
  0% {
    background-color: #f3f9ff;
  }
  10% {
    background-color: red;
  }
  100% {
    background-color: #f3f9ff;
  }
}

tbody tr:nth-child(2n-1) .highlightchange0 {
  animation: kfhighlightchangeodd 10s;
}

tbody tr:nth-child(2n) .highlightchange0 {
  animation: kfhighlightchangeeven 10s;
}

tbody tr:nth-child(2n-1) .highlightchange1 {
  animation: kfhighlightchangeodd1 10s;
}

tbody tr:nth-child(2n) .highlightchange1 {
  animation: kfhighlightchangeeven1 10s;
}

.classes-onekp-container {
  display: inline-flex;
  width: 190px;
}

#kplist {
  column-width: 250px;
}
#kplist > li:nth-child(odd) {
  background-color: #FAFAFA;
}
#kplist > li:nth-child(even) {
  background-color: #F5F5F5;
}
#kplist > div:nth-child(odd) {
  background-color: #FAFAFA;
}
#kplist > div:nth-child(even) {
  background-color: #F5F5F5;
}

#kplist .itemtext {
  padding-left: 10px;
}
#kplist .kp {
  font-weight: bold;
  text-align: center;
}
#kplist .kpnr {
/*  font-size: x-large;*/
  padding: 7px;
}
