.kpmarker .leaflet-popup-content {
  margin: 0px;
}
.kpmarker-svg-defeault {
  width:3em;
  height:3em;
  fill:red;
  -webkit-transform:translate(-50%,-50%);
  -moz-transform:translate(-50%,-50%);
  -ms-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
}
